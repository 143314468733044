import React, { useContext, useEffect, useState } from "react";
import StepOne from "../components/Steps/StepOne";
import StepTwo from "../components/Steps/StepTwo";
import PaymentNavbar from "../components/PaymentNavbar/PaymentNavbar";
import { UserContext } from "../components/Context";
import { navigate } from "gatsby";
import Seo from "../components/Seo";
import ServiceListData from "../components/serviceListData";
import { toast } from "react-toastify";
import axios from "axios";
import { getUser } from "../utils/getUser";
import { createUser } from "../utils/createUser";
import { getOffer } from "../utils/getOffer";
import confirmOrder from "../utils/confirmOrder";
import { createOrder } from "../utils/createOrder";
import FreeStepTwo from "../components/Steps/FreeStepTwo";
import FreeStepOne from "../components/Steps/FreeStepOne";



interface ServiceData {
  postsData: string;
  email: string;
  username: string;
  service: string;
  serviceId: string;
  links: [],
  totalQuantity: number;
  amount: number;
  quantity: number;
  type: string;
  currencyType: string;
  offer: boolean;
}

let myParam: string;
let username : string;
const Service = () => {
  const {
    setResponse,
    count,
    posts,
    setPosts,
    selectCurrency,
    buyBtnPrice,

  } = useContext(UserContext) as any;

  const [steps, setSteps] = useState("FirstStep");
  const [isLoading, setIsLoading] = useState(false);
  const [state, setState] = useState({
    email: "",
    username: "",
    service: "",
    serviceId: 0,
    amount: 0,
    postsData: {},
    links: [],
    totalQuantity: 0,
    quantity: 0,
    type: "",
    currencyType: selectCurrency,
  });

  if (typeof window !== "undefined") {
    const urlParams = new URLSearchParams(window.location.search);
    myParam = urlParams.get("id") as any;
    username = urlParams.get("username") as any;
  }


  let quantity = 50;
  if (myParam === "likes") {
    quantity = 50
  } else if (myParam === "views") {
    quantity = 500
  } else if (myParam === "followers") {
    quantity = 50
  }



  const fetch = async () => {
    return await ServiceListData(myParam, selectCurrency);
  };
  useEffect(() => {
    fetch().then((res) => {
      setPosts(res);
    });
  }, [selectCurrency]);

  const freeOrder = async (data: ServiceData) => {
    const { serviceId,
      quantity,
      type,
      username,
      offer,
      email,
      amount ,links} = data
    try {
      const orderData = {
        serviceId,
        quantity,
        type,
        userName: username,
        offer,
        email,
        amount,
      } as any;



      const checkout = await confirmOrder(orderData);
      if (checkout) {
        const storeOrderData = {
          orderId: `${checkout.data.orderId}`,
          amount,
          post: links,
          type,
          quantity
        } as any;
        createOrder(storeOrderData, username);
        setResponse({ storeOrderData, userName: username });
        navigate("/thankyou");
      }
    } catch (error: any) {
      console.log("erroe",error)
      toast.error(error.message);
    }
  };



  const onclick = async (datas: { email: string; like: string; username: string; }) => {

    setIsLoading(true);
    const service_id =
      posts &&
      posts.filter(
        (item: any) => item.type + 's' === datas.like.split(" ")[1]
      )[0]?.service;
    const type =
      posts &&
      posts.filter(
        (item: any) => item.type + 's' === datas.like.split(" ")[1]
      )[0].type;

    try {
      const { status } = (await getUser(datas.username)) as any;

      if (status) {
        toast.error("you are already used free offer");
        return
      }
      createUser(datas.username, datas.email, datas.like);
      const config = {
        method: "GET",
        url: `${process.env.BACKEND_URL}/api/posts/${datas.username}`,
      };
      const { data } = await axios(config);
     
      if (data.status) {
        setState({
          postsData: data.result,
          email: datas.email,
          username: datas.username,
          service: datas.like,
          serviceId: service_id,
          links: [],
          totalQuantity: quantity,
          amount: 0,
          quantity: quantity,
          type,
          currencyType: selectCurrency,
        });
       
        if(type === "follower"){
         
          freeOrder({
            postsData: data.result,
            email: datas.email,
            username: datas.username,
            service: datas.like,
            serviceId: service_id,
            links: [],
            totalQuantity: quantity,
            amount: 0,
            quantity: quantity,
            type,
            currencyType: selectCurrency,
            offer: true
          });
        }
          if(type!== "follower"){
            setSteps("SecondStep");
          }


      }
      else {
        toast.error(data.msg);
      }

    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const navigateSteps = () => {
    switch (steps) {
      case "FirstStep":
        return (
          <>
            {posts && posts.length !== 0 ? (
              <FreeStepOne
                onclick={onclick}
                isLoading={isLoading}
                posts={posts}
                id={myParam}
                username={username}
              />
            ) : null}
          </>
        );
      case "SecondStep":
        return (
          <FreeStepTwo state={state} setstate={setState} setSteps={setSteps} quantity={quantity}/>
        );
    }
  };

  return (
    <>

      <Seo title="Buy Service" description="Buy Instagram Service page" />
      <div className="overflow-hidden">
        <PaymentNavbar steps={steps} />
        {navigateSteps()}
      </div>
    </>
  );
};

export default Service;